<!--
 * @Description: 列表组件，用于首页、全部商品页面的商品列表
 -->
<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="item in list" :key="item.ID">
        <router-link :to="{ path: '/detail', query: { id: item.ID } }" target="_blank">
          <img
            :src="item.Picture"
            alt
            @mouseover="handleHoverGoodsImage(item.ID)"
            @mouseout="handleLeaveGoodsImage"
          />
        </router-link>
        <el-row class="imageHover" v-show="hoverGoodsId === item.ID">
          <el-col :span="8">一键下载</el-col>
          <el-col :span="8"
            ><div @click="imageStore(item.ID)">收藏</div></el-col
          >
          <el-col :span="8"
            ><div @click="imageSearch(item.Picture)">找相似</div></el-col
          >
        </el-row>
        <el-row>
          <div class="goodsInfo">
            {{ item.PName }} 
          </div>
          <div class="goodsPrice">
            <div class="priceNum">{{ item.skuFlag > 0 ? item.price : "" }}</div>
            <div class="createTime">{{ item.ITime ? item.ITime.substr(0, 10) : '' }}</div>
          </div>
          <div class="goodsSupply">
            <div
              class="daifa"
              v-if="item.skuFlag > 0"
            >
              代
            </div>
            <div class="supplyName">{{ item.SName }}</div>
          </div>
        </el-row>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "GoodsList",
  props: ["list"],
  data() {
    return {
      hoverGoodsId: 0,
    };
  },
  computed: {
    ...mapGetters(["getUser", "getNum"]),
  },
  methods: {
    /**
     * 鼠标悬浮商品图片
     * @param {*} id
     */
    handleHoverGoodsImage(id) {
      this.hoverGoodsId = id;
    },
    /**
     * 鼠标离开商品图片
     */
    handleLeaveGoodsImage() {
      setTimeout(() => {
        this.hoverGoodsId = 0;
      }, 5000);
    },
    /**
     * 图片搜索
     * @param {*} url
     */
    async imageSearch(url) {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/web/goods/image_search?url=" + url
      );
      if (data.code === 1) {
        this.$router.push({
          path: "/search",
          query: { data: JSON.stringify(data.data) },
        });
      }
    },
    /**
     * 单品收藏
     * @param {*} id
     */
    async imageStore(id) {
      if (!this.$store.getters.getUser) {
        this.$store.dispatch("setShowLogin", true);
        return false;
      }
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/fav/save",
        { uuid: this.$store.getters.getUser.uuid, pid: id }
      );
      this.notifySucceed(data.msg);
    },
  },
};
</script>
<style scoped>
.myList ul li {
  z-index: 1;
  float: left;
  width: 232px;
  height: 290px;
  padding-bottom: 10px;
  margin: 0 0 10px 15px;
  background-color: white;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
}
.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.myList ul li img {
  display: block;
  width: 210px;
  height: 210px;
  background: url(../assets/imgs/placeholder.png) no-repeat 50%;
  margin: 0 auto;
}
li .imageHover {
  width: 210px;
  margin: 0 auto;
  position: fixed;
  bottom: 90px;
  height: 30px;
  left: 10px;
  line-height: 30px;
  background: rgb(240, 66, 63);
  color: #fff;
  font-size: 12px;
}
li .imageHover .el-col-8 {
  text-align: center;
  border-left: 1px solid #ff9da3;
  border-right: 1px solid #ff9da3;
}
li .imageHover .el-col-8:hover {
  cursor: pointer;
}
.myList ul li .goodsInfo {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  padding: 0 20px 0 10px;
}
.myList ul li .goodsName {
  padding: 0 10px;
  width: 210px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #8f8f8f;
}
.myList ul li .goodsName:hover {
  color: #fb4d4f;
}
li .goodsPrice {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
  padding: 0 10px;
}
.goodsPrice .priceNum {
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  color: #ed3f41;
  height: 18px;
  line-height: 18px;
}
.goodsPrice .createTime {
  width: 100px;
  text-align: right;
  color: #afafaf;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
}
li .goodsSupply {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}
.goodsSupply .daifa {
  font-size: 12px;
  color: #fff;
  width: 18px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  vertical-align: top;
  text-align: center;
  padding: 2px 2px;
  background: #9e51ed;
}
.goodsSupply .supplyName {
  width: 190px;
  font-size: 12px;
  text-align: right;
  padding: 8px 0 0 0;
  color: #afafaf;
  overflow: hidden;
}
.myList ul li:hover {
  display: block;
}
</style>