<!--
 * @Description: 首页组件
 -->
<template>
  <div class="content">
    <div class="content-box">
      <el-row style="padding-left: 10px; background: #fff">
        <!-- 类别展开 -->
        <el-col :span="5" class="types">
          <div
            v-for="(type, k) in typeArray"
            :key="k"
            :style="k === 0 ? 'margin-top: 0' : 'margin-top: 10px'"
          >
            <div class="type-title">{{ type.name }}</div>
            <div
              class="type-child"
              style="display: inline-block"
              v-for="(childType, index) in type.child"
              :key="index"
            >
              <router-link
                :to="
                  'goods?type=' + childType.id + '&type_text=' + childType.name
                "
                target="_blank"
                >{{ childType.name }}</router-link
              >
            </div>
          </div>
        </el-col>
        <!-- 类别展开结束 -->
        <el-col :span="19">
          <!-- 轮播图 -->
          <div style="max-width: 1100px">
            <el-carousel height="390px">
              <el-carousel-item
                v-for="item in carousel"
                :key="item.carousel_id"
                :interval="2000"
              >
                <img
                  style="height: 390px; width: 100%"
                  :src="item.image"
                  :alt="item.title"
                  @click="redirectDiffPage(item)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 轮播图END -->
          <!-- 快捷功能 -->
          <div style="margin-top: 8px; max-width: 1100px; height: 215px">
            <div class="quick-f">
              <router-link
                :to="{
                  path: '/shop',
                  query: { SID: 16 },
                }"
                target="_blank"
              >
                <el-image :src="require('@/assets/imgs/f1.jpg')" style="max-width: 190px;"></el-image>
              </router-link>
            </div>
            <div class="quick-f">
              <router-link
                :to="{
                  path: '/shop',
                  query: { SID: 8 },
                }"
                target="_blank"
              >
                <el-image :src="require('@/assets/imgs/f2.jpg')" style="max-width: 190px;"></el-image>
              </router-link>
            </div>
            <div class="quick-f">
              <router-link
                :to="{
                  path: '/shop',
                  query: { SID: 32 },
                }"
                target="_blank"
              >
                <el-image :src="require('@/assets/imgs/f3.png')" style="max-width: 190px;"></el-image>
              </router-link>
            </div>
            <div class="quick-f">
              <router-link
                :to="{
                  path: '/shop',
                  query: { SID: 9 },
                }"
                target="_blank"
              >
                <el-image :src="require('@/assets/imgs/f4.png')" style="max-width: 190px;"></el-image>
              </router-link>
            </div>
          </div>
          <!-- 快捷功能END -->
        </el-col>
      </el-row>
      <div style="height: 20px">&nbsp;</div>
      <div class="main-box">
        <div class="main">
          <!-- 品牌商家展示区域 -->
          <div style="background: #fff">
            <el-row>
              <el-col :span="2" class="title">品牌商家</el-col>
              <el-col :span="20" class="desc"
                >毛绒之家最全商家，最新毛绒玩具批发火爆热销中</el-col
              >
              <el-col :span="2" class="more">
                <router-link to="goods" target="_blank">更多</router-link>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="2">
                <ul class="shopList">
                  <li
                    v-for="(item, key) in shops"
                    :key="key"
                    @mouseenter="switchShopGoods(item.sp_id)"
                    :class="item.sp_id == hoverShopId ? 'hoverLi' : ''"
                  >
                    <router-link
                      :to="{
                        path: '/shop',
                        query: { SID: item.sp_id },
                      }"
                      target="_blank"
                    >
                      <a>{{ item.title }}</a>
                    </router-link>
                  </li>
                </ul>
              </el-col>
              <el-col
                :span="22"
                style="padding-right: 10px"
                v-loading="loading"
                element-loading-text="加载中"
              >
                <el-row>
                  <div
                    style="
                      width: 58%;
                      display: inline-block;
                      height: 470px;
                      padding-left: 2%;
                    "
                  >
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="getArrayKeyValue(shopGoods, 0, 'image')"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                  <div
                    style="
                      width: 39%;
                      display: inline-block;
                      height: 470px;
                      padding-left: 1%;
                    "
                  >
                    <el-row :gutter="10">
                      <el-col
                        :span="12"
                        class="twoEach"
                        v-for="(v, k) in [1, 2]"
                        :key="k"
                      >
                        <router-link
                          :to="{
                            path: '/detail',
                            query: { id: getArrayKeyValue(shopGoods, v, 'id') },
                          }"
                          target="_blank"
                        >
                          <el-image
                            :src="getArrayKeyValue(shopGoods, v, 'Picture')"
                          ></el-image>
                          <p>{{ getArrayKeyValue(shopGoods, v, "name") }}</p>
                        </router-link>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col
                        :span="12"
                        class="twoEach"
                        v-for="(v, k) in [3, 4]"
                        :key="k"
                      >
                        <router-link
                          :to="{
                            path: '/detail',
                            query: { id: getArrayKeyValue(shopGoods, v, 'id') },
                          }"
                          target="_blank"
                        >
                          <el-image
                            :src="getArrayKeyValue(shopGoods, v, 'Picture')"
                          ></el-image>
                          <p>{{ getArrayKeyValue(shopGoods, v, "name") }}</p>
                        </router-link>
                      </el-col>
                    </el-row>
                  </div>
                </el-row>
                <el-row>
                  <ul class="fiveList">
                    <li
                      v-for="(item, index) of shopGoods.slice(5, 10)"
                      :key="index"
                    >
                      <router-link
                        :to="{
                          path: '/detail',
                          query: { id: item ? item.id : 0 },
                        }"
                        target="_blank"
                      >
                        <el-image :src="item ? item.Picture : ''"></el-image>
                        <p>{{ item ? item.name : "" }}</p>
                      </router-link>
                    </li>
                  </ul>
                </el-row>
                <!-- <GoodsList :list="shopGoods"></GoodsList> -->
              </el-col>
            </el-row>
          </div>
          <!-- 品牌商家展示区域END -->
          <!-- 热门视频展示区域 -->
          <div style="margin-top: 10px">
            <el-row>
              <el-col :span="2" class="title">热门短视频</el-col>
              <el-col :span="20" class="desc"
                >毛绒之家热播毛绒玩具短视频
                最火爆的毛绒玩具批发短视频专区</el-col
              >
              <el-col :span="2" class="more">
                <router-link to="goods" target="_blank">更多</router-link>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <div>
                  <video-player
                    id="videoPlayer"
                    class="video-player vjs-custom-skin middle-video"
                    ref="videoPlayer"
                    :playsinline="true"
                    :options="videoGoodOption"
                  >
                  </video-player>
                </div>
              </el-col>
              <el-col :span="7">
                <div>
                  <el-image
                    :src="playedVideoPoster"
                    style="height: 362.5px"
                  ></el-image>
                  <div class="rightVideoCover">
                    <router-link
                      :to="{
                        path: '/shop',
                        query: { SID: playedItem.SID },
                      }"
                      target="_blank"
                      ><div class="tabTitle">点击进入</div></router-link
                    >
                    <div class="tabShopName">{{ playedItem.SName }}</div>
                    <p class="tabShopAddress">
                      {{ playedItem.Addr }}
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <ul class="fiveVideo" v-if="videoGoods">
                <li
                  v-for="(item, index) of videoGoods.slice(1, 6)"
                  :key="index"
                >
                  <el-row class="videoInfo">
                    <el-col :span="24">
                      <img :src="item ? item.cover : ''" class="videoCover" />
                      <img
                        :src="
                          playedVideoId === item.ID
                            ? require('@/assets/imgs/stop.png')
                            : require('@/assets/imgs/play.png')
                        "
                        class="playButton"
                        @click="playVideo(item)"
                      />
                    </el-col>
                    <router-link
                      :to="{
                        path: '/detail',
                        query: { id: videoGoods[index + 1].ID },
                      }"
                      target="_blank"
                    >
                      <el-col :span="24" class="videoName">{{
                        videoGoods[index + 1].PName
                      }}</el-col>
                      <el-col :span="24" class="videoTime"
                        >播放{{ videoGoods[index + 1].PHotNum }}次</el-col
                      >
                    </router-link>
                  </el-row>
                </li>
              </ul>
            </el-row>
          </div>
          <!-- 热门视频展示区域END -->
          <!-- 精选单品展示区域 -->
          <div style="margin-top: 10px">
            <el-row>
              <el-col :span="2" class="title">精选单品</el-col>
              <el-col :span="20" class="desc"
                >毛绒之家精选毛绒玩具公仔 儿童玩具 小孩玩具 创意抱枕 等身抱枕
                沙发靠垫 汽车坐垫 沙发坐垫</el-col
              >
              <el-col :span="2" class="more">
                <router-link to="goods" target="_blank">更多</router-link>
              </el-col>
            </el-row>
            <el-row>
              <GoodsList
                :list="allRecGoods"
                v-if="allRecGoods.length > 0"
                style="position: relative; left: -10px; width: 1240px"
              />
            </el-row>
          </div>
          <!-- 精选单品展示区域END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import type from "../assets/json/type.json";
import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import GoodsList from "../components/GoodsList.vue";

Vue.use(VideoPlayer);

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      videoOptions: [{}],
      typeArray: type,
      carousel: [],
      shops: [],
      hoverShopId: 0,
      shopGoods: [],
      videoGoods: [],
      videoGoodOption: {},
      playedVideoId: 0,
      playedItem: { SID: 1, SName: "", Addr: "" },
      playedVideoPoster: "",
      allRecGoods: [], // 推荐商品数据
    };
  },
  created() {
    this.initHomeData();
    // 初始化视频列表
    this.setVideoGoods();
    this.setAllRecGoods();
  },
  methods: {
    /**
     * 获取首页数据
     */
    async initHomeData() {
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/home/index"
      );
      this.carousel = data.data.banner;
      // 设置品牌商家
      this.shops = data.data.supply;
      if (this.shops[0]) {
        this.switchShopGoods(this.shops[0].sp_id);
      }
      this.loading = false;
    },
    /**
     * 获取视频列表
     */
    async setVideoGoods() {
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/goods/video_list"
      );
      this.videoGoods = data.data;
      const item = this.videoGoods ? this.videoGoods[0] : [];
      this.playedItem = item;
      this.playedVideoPoster = item.cover;
      this.videoGoodOption = {
        autoplay: false,
        loop: true,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          fullscreenToggle: true, // 是否显示全屏按钮
        },
        sources: [
          {
            type: "video/mp4",
            src: item.video,
          },
        ],
        poster: item.cover,
      };
    },
    /**
     * 轮播图重定向
     * @param {*} page
     */
    redirectDiffPage(page) {
      if (page) {
        // 外链跳转
        if (page.type === 3 && page.url) {
          window.open(page.url, "_blank");
        }
        // 商家跳转
        if (page.sp_id) {
          const routerUrl = this.$router.resolve( { path: '/shop', query: { SID : page.sp_id}})
          window.open(routerUrl.href, "_blank");
        }
      }
    },
    /**
     * 品牌商家商品切换
     * 加入缓存storage?
     * @param {*} shopId
     */
    async switchShopGoods(shopId) {
      this.loading = true;
      this.hoverShopId = shopId;
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/goods/goods_list",
        { SID: shopId }
      );
      if (data) {
        const shopGoodsData = data.data.list.slice(0, 10);
        this.shopGoods = this.formatGoods(shopGoodsData);
      }
      this.loading = false;
    },
        /**
     * 获取所有商品
     */
     async setAllRecGoods() {
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/web/goods/list?area_id=1&type=1"
      );
      this.allRecGoods = data.data.list;
    },
    /**
     * 播放视频
     * @param {*} item
     */
    playVideo(item) {
      this.playedVideoId = item.ID;
      this.playedItem = item;
      this.playedVideoPoster = item.cover;
      this.videoGoodOption = {
        autoplay: true,
        loop: true,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          fullscreenToggle: true, // 是否显示全屏按钮
        },
        sources: [
          {
            type: "video/mp4",
            src: item.video,
          },
        ],
        poster: item.cover,
      };
    },
    /**
     * 获取对象数组key值
     * @param {*} array
     * @param {*} index
     * @param {*} key
     */
    getArrayKeyValue(array, index, key) {
      if (array) {
        if (array[index]) return array[index][key];
        else return key == "id" ? 0 : "";
      }
    },
    /**
     * 格式化品牌商家商品数组
     * @param {*} goods
     */
    formatGoods(goods) {
      let newA = [];
      for (let item of goods) {
        let imageUrl = "";
        if (item) {
          imageUrl = item.Picture;
          imageUrl = imageUrl.replace("UpLoad", "EnPic");
          imageUrl = imageUrl.substring(0, imageUrl.lastIndexOf(".")) + ".dat";
        }
        newA.push({
          id: item ? item.ID : 0,
          image: imageUrl,
          Picture: item.Picture,
          skuFlag: item ? item.skuFlag : 0,
          name: item ? item.PName : "",
        });
      }
      return newA;
    },
    /**
     * 跳转商品详情
     * @param {*} id
     */
    showDetail(id) {
      this.$router.push("/detail?id=" + id);
    },
  },
  components: { GoodsList },
};
</script>

<style scoped>
@import "../assets/css/index.css";
</style>
